import React from "react"

export default ({ children }) => (
    <div id="center">
      <div id="squeeze">
        <div className="right-corner">
          <div className="left-corner">
            {children}
            <div id="footer"/>
          </div>
        </div>
      </div>
    </div>
)