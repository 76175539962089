import React from "react"
import { inject, observer } from "mobx-react"
import { Link } from "gatsby"
import { FaShoppingCart } from 'react-icons/fa'

const iconStyle = {
  fontSize: '24px',
}


export default inject('store')(
  observer( ({store}) => (
    <span style={{ position: 'relative' }}>
      {store.itemCount > 0 &&
      <Link to="/cart">
          <span style={iconStyle} className="cart-badge" data-count={store.itemCount}>
            <FaShoppingCart/>
          </span>
      </Link>
      }
    </span>
)))
