import React from "react"
import ParentLayout from "./layout-parent"
import CenterPane from "./layout-center-pane"

export default (props) => (
  <ParentLayout {...props}>
    <CenterPane>
      {props.children}
    </CenterPane>
  </ParentLayout>
)