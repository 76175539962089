import React from "react"
import { Link } from "gatsby"
import ShoppingCartBadge from "./shopping-cart-badge"

const ListLink = props => (
  <li>
    <Link to={props.to}>{props.children}</Link>
  </li>
)

export default (props) => (
  <div className={ props.className }>
    <div className="center">
      <div className="bg-top"></div>
      <div className="bg-mid">
        <div className="header"><a href="/"><img className="logo" src="/images/logo.png" alt=""/></a>
          <div style={{float: 'right', margin:'10px', color:'#fff', height: '30px'}}><a href="/contact" style={{color: '#fff'}}>Contact
            Us</a></div>
          <div style={{paddingRight: '90px', float: 'right'}}><a
            href="https://itunes.apple.com/us/app/noteimals/id590029288?mt=8"><img
            src="/images/Download_on_the_App_Store_Badge_US-UK_135x40.png" alt=""/></a></div>
          {/*<div className="title">Welcome to Noteimals: Teaching Kids Music</div>*/}
          <div className="title">{props.title || 'Welcome to Noteimals: Teaching Kids Music'}</div>
        </div>

        <br/><br/>
        <ul className="links primary-links">
          <ListLink to="/">Home</ListLink>
          <ListLink to="/materials/piano">Products</ListLink>
          <ListLink to="/how-it-works">How it works</ListLink>
          <ListLink to="/technique/toddlers">Kids Ages</ListLink>
          <ListLink to="/teaching-tips">Teaching Tips</ListLink>
          <ListLink to="/schools">Schools</ListLink>
          <ListLink to="/faq">FAQ</ListLink>
          <ListLink to="/about">About</ListLink>
          <li><ShoppingCartBadge/></li>
        </ul>


        <div className="body">
          <div id="wrapper">
            <div id="container" className="clear-block">
              {props.children}
            </div>
          </div>

        </div>
      </div>
      <div className="bg-bottom"/>
    </div>
    <div className="copyright">Copyright &copy; 1995-2021 Noteimals, LLC. All rights reserved.<br/>Noteimals and Animal
      Notes are registered trademarks of Noteimals, LLC.</div>
  </div>
)