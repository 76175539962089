import React from "react"
// import { Link } from "gatsby"
import Layout from "../components/layout"
import {observer, inject} from 'mobx-react'
import { decorate } from "mobx"
import { Link, navigate } from "gatsby"
import { FaArrowLeft, FaArrowRight } from 'react-icons/fa'


class Checkout extends React.Component {

  constructor(props) {
    super(props)
    this.state = { shipping: 'domestic' }
    this.store = props.store
  }

  render () {
    let { store } = this.props
    return (
      <Layout>
        <h2>Checkout ({store.itemCount} items)</h2>
        <br/>
        {store.itemCount ?
          <div>
            <table>
              <thead>
              <tr>
                <th>Item Name</th>
                <th style={{ width: '100px' }}>Price</th>
                <th style={{ width: '30px' }}>Count</th>
                <th>Line Total</th>
              </tr>
              </thead>
              <tbody>
              {store.items.map((item, i) => (
                <tr key={item.name}>
                  {/*<div className="scItem">*/}
                  <td>{item.name}</td>
                  <td>{store.formatCurrency(item.unit_price)}</td>
                  <td>{item.count}</td>
                  <td style={{ textAlign: 'right', width: '80px' }}>{store.formatCurrency(item.unit_price * item.count)} &nbsp;</td>
                </tr>
              ))}
              <tr>
                <td colSpan="1"/>
                <td colSpan="3">
                  <hr/>
                </td>
              </tr>
              <tr>
                <td colSpan="3" align="right">Cart Total:</td>
                <td style={{ textAlign: 'right', width: '80px' }}>{store.formatCurrency(store.total)} &nbsp;</td>
              </tr>
              <tr>
                <td colSpan="2" align="right">Shipping To:</td>
                <td><select defaultValue="domestic" onChange={e => {
                  this.setState({shipping: e.target.value})
                }}>
                  <option value="domestic">United States</option>
                  <option value="foreign">Outside US</option>
                </select></td>
                <td style={{ textAlign: 'right'}}>{store.formatCurrency(this.calcShipping())} &nbsp;</td>
              </tr>
              <tr>
                <td colSpan="1"/>
                <td colSpan="3">
                  <hr/>
                </td>
              </tr>
              <tr>
                <td colSpan="3" align="right">Order Total:</td>
                <td style={{ textAlign: 'right'}}>{store.formatCurrency(this.calcTotal())}  &nbsp;</td>
                </tr>
              </tbody>
            </table>
            <Link to="" onClick={() => window.history.back()}><FaArrowLeft/> Back to Cart</Link> &nbsp;&nbsp;
            {/*<Link style={{float: "right"}} to="/checkout">Checkout with PayPal<FaArrowRight/></Link>*/}
            <div id="paypal-button-container" style={{width: '300px', float: 'right'}}/>
          </div> : <div>
            <p>Your shopping cart is empty.</p>
            <Link to="" onClick={() => window.history.back()}><FaArrowLeft/> Go Back</Link> &nbsp;&nbsp;
            <Link style={{ float: "right" }} to="/store">Go to product list <FaArrowRight/></Link>
          </div>
        }
        <br/><br/>

      </Layout>
    )
  }

  calcShipping() {
    return this.store.calculateShipping(this.state.shipping === 'domestic')
  }

  calcTotal() {
    return this.store.total + this.calcShipping()
  }

  componentDidMount() {
    // const sandboxClientId = 'AZd8a4en9fCvHGZjTP3Lm8nk0OhSDYXqsz9akPxRpEIqq3HZD6ipGN35KBg1XUe2Qir1mFzSMIIJ58Xu'
    const liveClientId = 'AdEimrtAxsQRvU-7XWBx8sEsKVnGRxiwJUeC-o90MW4mAybqySubXWMFa4B2u-0T1eebaOjAhh0BAv_f'
    const s = document.createElement('script')
    s.src = 'https://www.paypal.com/sdk/js?client-id=' + liveClientId
    s.onload = () => {
      window.paypal.Buttons({
        createOrder: (data, actions) => {
        // Set up the transaction
        const transaction = {
          purchase_units: [{
            amount: {
              value: this.calcTotal().toFixed(2),
              breakdown: {
                item_total: {
                  value: this.store.total.toFixed(2),
                  currency_code: 'USD'
                },
                shipping: {
                  value: this.calcShipping().toFixed(2),
                  currency_code: 'USD'
                }
              }
            },
            items: this.store.items.map(item => ({
              name: item.name,
              unit_amount: {
                value: item.unit_price.toFixed(2),
                currency_code: 'USD'
              },
              quantity: item.count,
              sku: item.sku,
              category: 'PHYSICAL_GOODS'
            }))
          }]
        }
        return actions.order.create(transaction)
      },
        onApprove: (data, actions) => {
          // Capture the funds from the transaction
          return actions.order.capture().then(details => {
            // Show a success message to your buyer
            // alert('Transaction completed by ' + details.payer.name.given_name);
            // navigate to thank you page
            this.store.doEmpty()
            navigate('/thank-you?order=' + details.id)
          })
        }
    }
      ).render('#paypal-button-container')
    }
    // s.setAttribute('data-timestamp', +new Date())
    document.body.appendChild(s)
  }
}

decorate(Checkout, {
  render: observer
})

export default inject('store')(Checkout)

/*
 componentDidMount() {
    const s = document.createElement('script')
    s.src = '//just-comments.com/w.js'
    s.setAttribute('data-timestamp', +new Date())
    this.ref.current.appendChild(s)
  }*/